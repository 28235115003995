<template>
    <div
      class="w-full top-0 h-16 text-black shadow-lg px-4 align-middle sticky z-30 bg-white dark:bg-gray-800"
      align="right"
    >
      
       
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: "header-item",
    components: {
   
    },
  
    data() {
      return {
        load: false,
      };
    },
    
  };
  </script>
  