<template>
  <div>
    <label class="block mt-3 mb-1 text-left">Bölüm</label>
    <multiselect
      v-model="selected"
      :options="List"
      :searchable="false"
      :show-labels="false"
      :loading="load"
      track-by="id"
      label="name"
      placeholder="Seçilmedi"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

// networking
import axios from "axios";
import { sections } from "@/networking/urlmanager";

export default {
  name: "section-select",
  props: ["value"],
  model: {
    prop: "value",
    event: "change",
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      List: [],
      load: false,
      selected: false,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(sections.getAll, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data.map((r) => {
            return {
              id: r.id,
              name: r.name,
            };
          });

          this.selected = this.List.find((r) => r.id == this.value);
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU BÖLÜM VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },

  watch: {
    value(val) {
      this.selected = this.List.find((r) => r.id == val);
    },
    selected(val) {
      if (val) this.$emit("change", val.id);
    },
  },
};
</script>
