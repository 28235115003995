<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-5/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">GÖREVİ DÜZENLE</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="text-center w-full mt-5">
        <customInput
          :required="true"
          v-model="name"
          title="Görev Adı"
          type="text"
          min="5"
          max="100"
        />
      </div>

      <sectionSelect v-model="sectionId" />
      <surveySelect v-model="surveyId" />

      <div class="text-center w-full mt-2">
        <customInput
          :required="true"
          v-model="level"
          title="Görev Seviyesi"
          type="number"
          min="1"
          max="100"
        />
      </div>

      <div class="text-center w-full mt-2">
        <customInput
          title="Dosya"
          @change="previewFiles"
          type="file"
          :videoId="detail.id"
        />
      </div>

      <div class="text-center w-full mt-2">
        <customInput
          v-model="explanation"
          :required="true"
          title="Açıklama"
          type="textarea"
          min="1"
          max="100"
        />
      </div>

      <asnycButton :load="load" title="Kaydet" mainClass="mt-2" />
    </form>
  </div>
</template>

<script>
// general components
import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";

// general devItem components
import sectionSelect from "@/components/devItems/sectionSelect";
import surveySelect from "@/components/devItems/surveySelect";

// networking
import axios from "axios";
import { missions } from "@/networking/urlmanager";

export default {
  name: "edit-modal",
  props: ["detail"],
  components: {
    customInput,
    asnycButton,

    sectionSelect,
    surveySelect,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,

      name: "",
      level: "",
      sectionId: "",
      surveyId: "",
      explanation: "",
      file: null,
      load: false,
    };
  },
  methods: {
    openModal() {
      setTimeout(() => {
        this.name = this.detail.name;
        this.level = this.detail.level;
        this.sectionId = this.detail.missionId;
        this.surveyId = this.detail.surveyId;
        this.explanation = this.detail.explanation;
      }, 50);

      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    previewFiles(event) {
      this.file = event.target.files[0];
    },

    save() {
      this.load = true;

      const formItem = new FormData();
      formItem.append("rowId", this.detail.id);
      formItem.append("name", this.name);
      formItem.append("level", this.level);
      formItem.append("surveyId", this.surveyId);
      formItem.append("explanation", this.explanation);
      formItem.append("file", this.file);
      formItem.append("sectionId", this.sectionId);

      axios
        .post(missions.edit, formItem, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          this.name = "";
          this.level = "";
          this.explanation = "";
          this.surveyId = "";
          this.sectionId = "";
          this.file = null;

          this.$emit("refresh", true);
          this.load = false;
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          alert("Soru eklenirken bir sorun oluştu lütfen daha sonra tekrar deneyiniz!");
          this.load = false;
        });
    },
  },
};
</script>
