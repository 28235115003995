<template>
  <div class="w-full p-8">
    <div class="w-full flex items-center mt-4 text-3xl font-extralight">
      <i class="fas fa-tachometer-alt mr-2"></i>
      <h5 class="font-extralight">Genel Durum</h5>
    </div>
    <div class="md:flex mt-5 items-center">
      <div class="w-full md:w-8/12">
        <div class="w-7/12 flex items-center">
          <asyncBtn
            :load="statusLoad"
            :isSuccess="$store.state.userData.appState == 2"
            :title="
              $store.state.userData.appState == 1
                ? 'Uygulamayı Durdur'
                : 'Uygulamayı Başlat'
            "
            :button="true"
            @click="changeStatus()"
            icon="fas fa-exclamation-triangle"
          />


          <asyncBtn
            title="Excel Çıktısı Al"
            :button="true"
            @click="downloadRaport()"
            class="ml-2"
            icon="fas fa-download-alt"
          />
        </div>
      </div>

     
      <div
        class="w-full md:w-4/12 flex justify-center items-center xl:mt-1 mb-1 pr-1 pl-2 rounded md:mt-0 bg-white dark:bg-gray-300 shadow mt-4"
      >
        <i class="fas fa-calendar w-2/12 text-center"></i>

        <div class="p-2 border">
          <label class="text-xs border-b block py-1">Başlangıç</label>
          <input type="date" v-model="startDate" :max="endDate" class="p-2" />
        </div>
        <div class="p-2 border-r-0 border-t border-b">
          <label class="text-xs border-b block py-1">Bitiş</label>
          <input type="date" v-model="endDate" :min="startDate" class="p-2" />
        </div>
      </div>
    </div>

    

    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
      style="min-height: 300px !important"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th scope="col" class="table-th text-center">#</th>
            <th scope="col" class="table-th text-center">Ad-soyad</th>
            <th scope="col" class="table-th text-center">Adım Sayısı</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td text-center">{{ index + 1 }}</td>
            <td class="table-td text-center">
              {{ item.fullname }}
            </td>
            <td class="table-td text-center">{{ item.stepCount }}</td>
            <!-- <td class="table-td text-center">
               <button class="process-btn mr-2" @click="$refs.detailModal.openModal()">
                <i class="fas fa-search"></i>
              </button> 

               <button class="process-btn" @click="deleteUser()">
                <i class="fas fa-trash"></i>
              </button> 
            </td> -->
          </tr>

          <tableLoader :load="load" :length="List.length" colspan="7" />
        </tbody>
      </table>
    </div>
    <PaginationComponents
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
// Global Components
import asyncBtn from "@/components/general/asyncBtn";
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";
// networking
import axios from "axios";
import { healt, auth } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "dashboard-page",
  components: {
    asyncBtn,
    tableLoader,
    PaginationComponents
  },
  data() {
    return {
      load: false,
      List: [],
      search: "",

      startDate: "",
      endDate: "",

      pageCount: 0,
      totalCount: 0,
      currentPage: 0,

      statusLoad: false,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(
          healt.getScoreAll + "?startDate=" + this.startDate + "&endDate=" + this.endDate,
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          // alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
    changeStatus() {
      if (
        confirm(
          "Uygulamayı " +
            (this.$store.state.userData.appState == 1 ? "Başlatmak" : "Durdurmak") +
            " İstediğinize emin misiniz ?"
        )
      ) {
        this.statusLoad = true;
        axios
          .post(
            auth.setApplicationStatus,
            {
              status: this.$store.state.userData.appState == 1 ? 2 : 1,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.$store.commit(
              "setApplicationStatus",
              this.$store.state.userData.appState == 1 ? 2 : 1
            );

            alert(
              "Uygalama Başarı ile " +
                (this.$store.state.userData.appState == 1 ? "Durduruldu" : "Başlatıldı")
            );
            this.statusLoad = false;
          })
          .catch((err) => {
            console.log(err);
            alert(
              "İşlem gerçekleştirilirken bir sorun oluştu lütfen daha sonra tekrar deneyiniz !"
            );
            this.statusLoad = false;
          });
      }
    },
    downloadRaport(){
      axios
        .get(
          auth.downloadExcel,
          {
            responseType: 'blob',
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const url = window.URL.createObjectURL(blob);

                    // Dosyayı indirme bağlantısını oluşturun
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'exportedExcel.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
          // alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    }
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    startDate(val) {
      if (val && this.endDate) this.getAll();
    },
    endDate(val) {
      if (val && this.startDate) this.getAll();
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
};
</script>

<style lang="scss" scoped></style>
