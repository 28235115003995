<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-5/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">YENİ ANKET</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="text-center w-full mt-5">
        <customInput
          v-model="name"
          :required="true"
          title="Anket Adı"
          type="text"
          min="5"
          max="100"
        />
      </div>
      <div class="text-left w-full mt-2">
        <label class="mb-1 block">Anket Türü</label>
        <select
          :required="true"
          v-model="typeId"
          class="w-full border py-3 px-2 rounded border-black border-opacity-20"
        >
          <option value="1">Başlangıç</option>
          <option value="2">Periyodik</option>
          <option value="3">Başlangıç + Periyodik</option>
        </select>
      </div>

      <div class="text-center w-full mt-2" v-if="typeId > 1">
        <customInput
          :required="true"
          title="Periyot (Gün)"
          type="number"
          min="1"
          max="366"
          v-model="periotDay"
        />
      </div>

      <div class="text-center w-full mt-2">
        <customInput
          :required="true"
          title="Anket Seviyesi"
          type="number"
          min="1"
          max="100"
          v-model="level"
        />
      </div>

      <div class="text-center w-full mt-2">
        <customInput
          v-model="explanation"
          :required="true"
          title="Açıklama"
          type="textarea"
        />
      </div>

      <asnycButton :load="load" title="Kaydet" mainClass="mt-2" />
    </form>
  </div>
</template>

<script>
import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";

import axios from "axios";
import { survey } from "@/networking/urlmanager";
export default {
  name: "add-modal",
  components: {
    customInput,
    asnycButton,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,

      name: "",
      typeId: "1",
      level: "1",
      periotDay: 0,
      explanation: "",
      load: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    save() {
      this.load = true;
      axios
        .post(
          survey.add,
          {
            name: this.name,
            typeId: this.typeId,
            level: this.level,
            explanation: this.explanation,
            periotDay: this.periotDay,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.load = false;
          this.$emit("refresh");
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          alert("Anket eklenemedi bir sorun oluştu lütfen daha sonra tekrar deneyiniz !");
          this.load = false;
        });
    },
  },
};
</script>
