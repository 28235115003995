<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-5/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">GÖREVİ DÜZENLE</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="text-center w-full mt-5">
        <customInput
          :required="true"
          v-model="name"
          title="SÖZ"
          type="textarea"
          min="1"
          max="255"
        />
      </div>

      <sectionSelect v-model="moduleId" />

      <div class="text-center w-full mt-5 flex items-center justify-between">
        <div class="w-6/12">
          <customInput
            :required="true"
            v-model="date"
            title="Tarihi"
            type="date"
          />
        </div>
        <div class="w-6/12 pl-2">
          <customInput
            :required="true"
            v-model="time"
            title="Saati"
            type="select"
            :optList="[
              {
                id: '09:00',
                name: '09:00',
              },
              {
                id: '10:00',
                name: '10:00',
              },
              {
                id: '11:00',
                name: '11:00',
              },
              {
                id: '12:00',
                name: '12:00',
              },
              {
                id: '13:00',
                name: '13:00',
              },
              {
                id: '14:00',
                name: '14:00',
              },
              {
                id: '15:00',
                name: '15:00',
              },
              {
                id: '16:00',
                name: '16:00',
              },
              {
                id: '17:00',
                name: '17:00',
              },
              {
                id: '18:00',
                name: '18:00',
              },
              {
                id: '19:00',
                name: '19:00',
              },
              {
                id: '20:00',
                name: '20:00',
              },
            ]"
          />
        </div>
      </div>

      <asnycButton :load="load" title="Kaydet" mainClass="mt-5" />
    </form>
  </div>
</template>

<script>
// general components
import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";
import sectionSelect from "@/components/devItems/sectionSelect.vue";

// networking
import axios from "axios";
import { dailywords } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "edit-modal",
  props: ["detail"],
  components: {
    customInput,
    asnycButton,
    sectionSelect,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,

      name: "",
      date: "",
      time: "",
      moduleId: "",
      load: false,
    };
  },
  methods: {
    openModal() {
      setTimeout(() => {
        this.name = this.detail.words;
        this.date = moment(this.detail.addDate).format("YYYY-MM-DD");
        this.time = moment(this.detail.addDate).format("HH:mm");

        this.moduleId = this.detail.moduleId;
      }, 50);

      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    save() {
      this.load = true;
      axios
        .post(
          dailywords.edit,
          {
            rowId: this.detail.id,
            message: this.name,
            moduleId: this.moduleId,
            date: this.date + "T" + this.time,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.name = "";
          this.date = "";

          this.$emit("refresh", true);
          this.load = false;
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          alert(
            "Soru eklenirken bir sorun oluştu lütfen daha sonra tekrar deneyiniz!"
          );
          this.load = false;
        });
    },
  },
};
</script>
