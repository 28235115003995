<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <addModal ref="addmodal" :surveyId="surveyId" @refresh="getAll()" />
    <editModal
      ref="editmodal"
      :surveyId="surveyId"
      :detail="selectedItem"
      @refresh="getAll()"
    />
    <div
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = showSubModal"
      class="w-full xl:w-8/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">SORULAR</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="md:flex mt-5">
        <div class="w-full md:w-9/12 flex">
          <button
            class="btn w-full md:w-56"
            @click="() => ((showSubModal = true), $refs.addmodal.openModal())"
          >
            <i class="fas fa-plus"></i> Yeni Soru
          </button>
        </div>
        <div
          class="w-full md:w-3/12 flex justify-center items-center xl:mt-1 mb-1 pr-1 pl-2 rounded md:mt-0 bg-white dark:bg-gray-300 shadow mt-4"
        >
          <i class="fas fa-search w-1/12 mr-2"></i>
          <input
            v-model="search"
            @keypress.enter="getAll()"
            type="search"
            class="w-11/12 border-none focus:ring-0 dark:bg-gray-300 p-2"
            placeholder="Aramak İçin Yazınız..."
          />
        </div>
      </div>

      <div
        class="shadow table-height-value overflow-auto border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
        style="min-height: 300px !important; max-height: 400px !important"
      >
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
            <tr>
              <th scope="col" class="table-th text-center">#</th>
              <th scope="col" class="table-th text-center">Soru</th>
              <th scope="col" class="table-th text-center">Cevap Sayısı</th>
              <th scope="col" class="table-th text-center">Tarih</th>
              <th scope="col" class="table-th text-center">Durum</th>
              <th scope="col" class="table-th text-center"></th>
            </tr>
          </thead>
          <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
            <tr v-for="(item, index) in List" :key="index">
              <td class="table-td text-center">
                {{ index + 1 }}
              </td>
              <td class="table-td text-left">
                {{ item.title }}
              </td>

              <td class="table-td text-center">0</td>

              <td class="table-td text-center">
                {{ item.createdDate }}
              </td>
              <td class="table-td text-center">
                {{ item.isActive == 1 ? "Aktif" : "Pasif" }}
              </td>
              <td class="table-td text-center flex items-center">
                <button
                  class="process-btn mr-2"
                  @click="
                    () => (
                      (showSubModal = true),
                      (selectedItem = item),
                      $refs.editmodal.openModal()
                    )
                  "
                >
                  <i class="fas fa-search"></i>
                </button>

                <button class="process-btn" @click="deleteItem(item)">
                  <i class="fas fa-trash"></i>
                </button>
              </td>
            </tr>
            <tableLoader :load="load" :length="List.length" colspan="7" />
          </tbody>
        </table>
      </div>
      <PaginationComponents :pageCount="1" :totalRowCount="2" />
    </div>
  </div>
</template>

<script>
import addModal from "./add.modal";
import editModal from "./edit.modal";

import PaginationComponents from "@/components/general/pagination.vue";
import tableLoader from "@/components/general/table-loader.vue";

import axios from "axios";
import { question } from "@/networking/urlmanager";

export default {
  name: "questionDetailModal",
  props: ["surveyId"],
  components: {
    PaginationComponents,
    tableLoader,

    addModal,
    editModal,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,
      showSubModal: false,

      load: false,
      search: "",
      selectedItem: {
        id: "",
        name: "",
        score: "",
      },
      List: [],
      pageCount: 1,
      totalRowCount: 1,
    };
  },
  methods: {
    openModal() {
      setTimeout(() => {
        this.showModal = true;
        this.getAll();
      }, 50);
    },
    closeModal() {
      this.showModal = false;
    },
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(question.getAll + "?surveyId=" + this.surveyId + "&search=" + this.search, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      if (confirm("Soruyu silmek istediğinize emin misiniz?")) {
        axios
          .post(
            question.delete,
            {
              rowId: item.id,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.getAll();
          })
          .catch((err) => {
            alert(
              "Anket silinirken bir hata oluştu lütfen daha sonra tekrar deneyiniz !"
            );
            console.log(err);
          });
      }
    },
  },
};
</script>
