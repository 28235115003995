<template>
  <div class="w-full p-8">
    <div class="w-full flex items-center mt-4 text-3xl font-extralight">
      <i class="fas fa-list-ul mr-2"></i>
      <h5 class="font-extralight">Bölümler</h5>
    </div>

    <addModal ref="addModal" @refresh="getAll()" />
    <editModal ref="editModal" :detail="selectedItem" @refresh="getAll()" />

    <div class="md:flex mt-5">
      <div class="w-full md:w-9/12 flex">
        <button class="btn w-full md:w-56" @click="() => $refs.addModal.openModal()">
          <i class="fas fa-plus"></i> Yeni Bölüm
        </button>
      </div>
      <div
        class="w-full md:w-3/12 flex justify-center items-center xl:mt-1 mb-1 pr-1 pl-2 rounded md:mt-0 bg-white dark:bg-gray-300 shadow mt-4"
      >
        <i class="fas fa-search w-1/12 mr-2"></i>
        <input
          v-model="search"
          @keypress.enter="getAll()"
          type="search"
          class="w-11/12 border-none focus:ring-0 dark:bg-gray-300 p-2"
          placeholder="Aramak İçin Yazınız..."
        />
      </div>
    </div>

    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
      style="min-height: 300px !important"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th scope="col" class="table-th text-center">#</th>
            <th scope="col" class="table-th text-left">Bölüm</th>
            <th scope="col" class="table-th text-center">Anket</th>
            <th scope="col" class="table-th text-center">Seviye</th>
            <th scope="col" class="table-th text-center">Oluşturulma Tarihi</th>
            <th scope="col" class="table-th text-center">Skor</th>
            <th scope="col" class="table-th text-center">İşlem</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td text-center">{{ index + 1 }}</td>
            <td class="table-td text-left">{{ item.name }}</td>
            <td class="table-td text-center">{{ item.surveyName }}</td>
            <td class="table-td text-center">{{ item.level }}</td>
            <td class="table-td text-center">{{ item.createdDate | getDate }}</td>
            <td class="table-td text-center">
              {{ item.minScore + " / " + item.maxScore }}
            </td>
            <td class="table-td text-center">
              <button
                class="process-btn mr-2"
                @click="() => ((selectedItem = item), $refs.editModal.openModal())"
              >
                <i class="fas fa-search"></i>
              </button>

              <button class="process-btn" @click="deleteItem(item)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>

          <tableLoader :load="load" :length="List.length" colspan="7" />
        </tbody>
      </table>
    </div>
    <PaginationComponents :pageCount="1" :totalRowCount="2" />
  </div>
</template>

<script>
// local components
import addModal from "./components/add.modal";
import editModal from "./components/edit.modal";

// Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";

// networking
import axios from "axios";
import { sections } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "section-table",
  components: {
    tableLoader,
    PaginationComponents,

    addModal,
    editModal,
  },
  data() {
    return {
      load: false,
      List: [],

      selectedItem: {
        id: "",
        name: "",
        level: "",
        explanation: "",
        surveyId: "",
      },

      search: "",
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(sections.getAll + "?search=" + this.search, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
    deleteItem(item) {
      if (confirm("Bölümü silmek istediğinize emin misiniz ?")) {
        axios
          .post(
            sections.delete,
            {
              rowId: item.id,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.getAll();
          })
          .catch((err) => {
            alert(
              "Bölüm silinirken bir hata oluştu lütfen daha sonra tekrar deneyiniz !"
            );
            console.log(err);
          });
      }
    },
  },
  created() {
    this.getAll();
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
};
</script>

<style lang="scss" scoped></style>
