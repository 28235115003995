<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-5/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">YENİ BÖLÜM</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="text-center w-full mt-5">
        <customInput
          v-model="name"
          :required="true"
          title="Bölüm Adı"
          type="text"
          min="1"
          max="500"
        />
      </div>

      <div class="text-center w-full mt-2">
        <customInput
          v-model="level"
          :required="true"
          title="Bölüm Seviyesi"
          type="number"
          min="1"
          max="100"
        />
      </div>

      <div class="xl:flex items-center justify-between">
        <div class="text-center w-full mt-2 xl:w-6/12 pr-2">
          <customInput
            v-model="minScore"
            :required="true"
            title="Minimum Skor"
            type="number"
            :min="0"
            :max="maxScore"
          />
        </div>
        <div class="text-center w-full mt-2 xl:w-6/12">
          <customInput
            v-model="maxScore"
            :required="true"
            title="Maksimum Skor"
            type="number"
            :min="minScore"
            max="2000000"
          />
        </div>
      </div>

      <div class="text-center w-full mt-2">
        <surveySelect v-model="surveyId" :required="true" title="Bölüm Seviyesi" />
      </div>
      <div class="text-center w-full mt-2">
        <customInput
          :required="true"
          v-model="explanation"
          title="Açıklama"
          type="textarea"
          min="1"
          max="500"
        />
      </div>

      <asnycButton :load="load" title="Kaydet" mainClass="mt-2" />
    </form>
  </div>
</template>

<script>
// general components
import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";

// general devItem components
import surveySelect from "@/components/devItems/surveySelect";

// networking
import axios from "axios";
import { sections } from "@/networking/urlmanager";

export default {
  name: "add-modal",
  components: {
    customInput,
    asnycButton,

    surveySelect,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,

      name: "",
      level: "",
      surveyId: "",
      explanation: "",
      minScore: 0,
      maxScore: 1,
      load: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    save() {
      this.load = true;
      axios
        .post(
          sections.add,
          {
            name: this.name,
            level: this.level,
            surveyId: this.surveyId,
            explanation: this.explanation,
            minScore: this.minScore,
            maxScore: this.maxScore,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.name = "";
          this.level = "";
          this.explanation = "";
          this.surveyId = "";
          this.$emit("refresh", true);
          this.load = false;
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          alert("Soru eklenirken bir sorun oluştu lütfen daha sonra tekrar deneyiniz!");
          this.load = false;
        });
    },
  },
};
</script>
