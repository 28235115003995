<template>
  <div
    class="min-h-screen w-[] border-blue-600 bg-custom-dark text-white border-r-2 border-opacity-5 z-50"
  >
    <div class="w-full h-full" align="left">
      <div
        class="w-full xl:w-8/12 pt-2 p-1 flex items-center justify-start bg-opacity-10 pb-2 ml-2"
      >
        <img src="../assets/logo-item.png" class="inline-block  bg-contain mt-4 mb-2" />
      </div>
      <div class="mb-3 mt-5 pr-2 pl-2 text-left">
        <button
          v-for="item in List"
          :key="item.id"
          @click="() => $router.push(item.id)"
          class="navbar-link text-white w-full mt-2 py-3 px-4"
          :class="{ active: $route.path == item.id }"
        >
          <i class="mr-3" :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </button>

        <button @click="signOut()" class="navbar-link text-white w-full mt- py-3 px-4">
          <i class="fas fa-sign-out-alt mr-3"></i>
          <span>Çıkış Yap</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sider-bar",
  components: {},
  data() {
    return {
      List: [
        {
          id: "/dashboard",
          name: "Genel",
          icon: "fas fa-tachometer-alt",
        },
        {
          id: "/survey",
          name: "Anketler",
          icon: "fas fa-paperclip",
        },
        {
          id: "/sections",
          name: "Bölümler",
          icon: "fas fa-list-ul",
        },
        {
          id: "/dailywords",
          name: "Günün Sözleri",
          icon: "fas fa-list-ul",
        },
        {
          id: "/missions",
          name: "Görevler",
          icon: "fas fa-video",
        },
        {
          id: "/users",
          name: "Kullanıcılar",
          icon: "fas fa-users",
        },
      ],
    };
  },
  methods: {
    signOut() {
      this.$store.commit("signOut");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.active {
  border-left: 3px solid #e43e1c !important;
  color: rgb(255, 255, 255) !important;
}
</style>
