import Vue from 'vue'
import VueRouter from 'vue-router'


// pages
import surveyPage from "../views/survey/index"
import sectionsPage from "../views/sections/index"
import missionPage from "../views/missions/index"
import dailywordsPage from "../views/dailywords/index"
import usersPage from "../views/users/index"
import generalPage from '../views/dashboard/index'

// auth
import authPage from '../views/auth/index.vue'



Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'authPage',
        component: authPage
    },
    {
        path: '/survey',
        name: 'surveyPage',
        component: surveyPage
    },
    {
        path: '/missions',
        name: 'missionPage',
        component: missionPage
    },
    {
        path: '/dailywords',
        name: 'dailywords',
        component: dailywordsPage
    },
    {
        path: '/sections',
        name: 'sectionPage',
        component: sectionsPage
    },
    {
        path: '/users',
        name: 'usersPage',
        component: usersPage
    },
    {
        path: '/dashboard',
        name: 'generalPage',
        component: generalPage
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router