<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-5/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">KULLANICI DETAYI</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <asnycButton title="Tamam" mainClass="mt-2" />
    </form>
  </div>
</template>

<script>
// import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";

export default {
  name: "detail-modal",
  components: {
    // customInput,
    asnycButton,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    save() {
      alert(1);
    },
  },
};
</script>
