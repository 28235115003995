import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userData: {
            isAuth: false,
            email: '',
            token: '',
            appState: 1
        },

    },
    getters: {},
    mutations: {
        signIn(state, data) {

            state.userData.email = data.email;
            state.userData.token = data.token;
            state.userData.appState = data.appState;
            state.userData.isAuth = true;
        },
        signOut(state) {
            state.userData.email = '';
            state.userData.token = '';
            state.userData.appState = 1;
            state.userData.isAuth = false;
        },
        setApplicationStatus(state, val) {
            state.userData.appState = val;
        }
    },
    actions: {},
    modules: {},
    plugins: [createPersistedState()],

})