const baseUrl = "http://35.208.134.237:6001";
// const baseUrl = "http://localhost:3000";


export const auth = {
    signIn: baseUrl + '/admin/sign/in',
    setPassword: baseUrl + '/admin/set/password',
    signOut: baseUrl + '/admin/sign/out',

    downloadExcel:baseUrl+'/admin/get/excel/export',
    setApplicationStatus: baseUrl + '/admin/set/application/status',
}


export const dailywords={
    getAll:baseUrl+'/admin/get/daily/words',
    add:baseUrl+'/admin/add/daily/words',
    edit:baseUrl+'/admin/edit/daily/words',
    delete:baseUrl+'/admin/delete/daily/words',
}



export const survey = {
    getAll: baseUrl + '/survey/get/admin/all',
    add: baseUrl + '/survey/add',
    edit: baseUrl + '/survey/edit',
    delete: baseUrl + '/survey/delete',
}

export const question = {
    getAll: baseUrl + '/survey/get/question/all',
    add: baseUrl + '/survey/question/add',
    edit: baseUrl + '/survey/question/edit',
    delete: baseUrl + '/survey/question/delete',
}


export const answers = {
    getAll: baseUrl + '/survey/get/answer/all',
    add: baseUrl + '/survey/answer/add',
    edit: baseUrl + '/survey/answer/edit',
    delete: baseUrl + '/survey/answer/delete'
}



export const sections = {
    getAll: baseUrl + '/sections/get/all',
    add: baseUrl + '/sections/add',
    edit: baseUrl + '/sections/edit',
    delete: baseUrl + '/sections/delete'
}


export const missions = {
    getAll: baseUrl + '/videos/get/general/all',
    getDetail: baseUrl + '/videos/get/detail',
    add: baseUrl + '/videos/add',
    edit: baseUrl + '/videos/edit',
    delete: baseUrl + '/videos/delete'
}


export const users = {
    getAll: baseUrl + '/users/get/all'
}

export const healt = {
    getScoreAll: baseUrl + '/healt/get/score/all'
}