<template>
  <div class="rounded w-full">
    <div class="w-full relative border-opacity-40 border-gray-300 px-0 rounded">
      <div class="flex justify-between w-full items-center">
        <h6 class="bg-white bg-opacity-80 pl-0 block text-left">
          {{ title }}
        </h6>

        <a
          class="block w-full text-right"
          v-if="videoId > 0 && !load"
          :href="url"
          target="_blank"
        >
          <span> Görüntüle </span>
        </a>
      </div>

      <input
        v-if="type != 'textarea' && type != 'select'"
        class="w-full rounded border border-gray-300 bg-white text-black text-sm mt-1 h-10 px-2"
        :type="type"
        :disabled="disabled"
        :required="required"
        v-model="selected"
        :min="min"
        :max="max"
        :minlength="min"
        :maxlength="max"
        @change="(r) => type == 'file' && $emit('change', r)"
      />


      <select 
      class="w-full rounded border border-gray-300 bg-white text-black text-sm mt-1 h-10 px-2"
      v-else-if="type=='select'"
      v-model="selected"
      >
      <option value="">Seçilmedi</option>

      <option v-for="item in optList" :value="item.id" :key="item.id">
        {{ item.name }}
      </option>
    
    </select>

      <textarea
        v-else
        class="w-full rounded border border-gray-300 bg-white text-black text-sm mt-1 textarea-size p-2"
        :type="type"
        :required="required"
        v-model="selected"
        rows="4"
      ></textarea>

      <!-- <tinymce v-else id="d1" class="w-full mt-2" :value="selected"></tinymce> -->
    </div>
  </div>
</template>
<script>
// import tinymce from "vue-tinymce-editor";

// networking
import axios from "axios";
import { missions } from "@/networking/urlmanager";

export default {
  name: "CostomerİnputPage",
  props: ["title", "type", "value", "required", "disabled", "videoId", "min", "max","optList"],
  components: {
    // tinymce,
  },
  data() {
    return {
      selected: this.value,
      load: false,
    };
  },
  model: {
    event: "change",
    prop: "value",
  },
  methods: {
    getUrl() {
      this.load = true;
      this.List = [];
      axios
        .get(missions.getDetail + "?rowId=" + this.videoId, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.url = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    if (this.type != "file") {
      this.selected = this.value;
    } else if (this.type == "file" && this.videoId) this.getUrl();
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(val) {
      this.$emit("change", val);
    },
    videoId(val) {
      if (this.type == "file" && val) this.getUrl();
    },
  },
};
</script>
<style>
.textarea-size {
  min-height: 150px !important;
  max-height: 150px !important;
}
</style>
