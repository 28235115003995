<template>
  <div
    class="fixed left-0 top-0 z-50 w-full h-screen bg-black bg-opacity-40 flex justify-center items-center overflow-y-auto py-10"
    v-if="showModal"
    @click="showModal = mouseState"
  >
    <form
      @submit.prevent="save()"
      @mouseenter="mouseState = true"
      @mouseleave="mouseState = false"
      class="w-full xl:w-4/12 bg-white rounded-xl shadow p-6"
    >
      <div class="w-full flex justify-between">
        <h4 class="text-black text-2xl">ANKETİ DÜZENLE</h4>
        <button class="text-center" @click="showModal = false">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="w-full mt-8">
        <customInput
          :required="true"
          v-model="title"
          title="Soru"
          type="textarea"
          min="2"
          max="1000"
        />
      </div>

      <div
        class="text-center w-full mt-3 flex justify-center items-end p-2"
        :class="!item.new && 'bg-gray-50 w-full  rounded'"
        v-for="(item, index) in answers"
        :key="index"
      >
        <div class="w-4/12">
          <customInput
            v-model="item.name"
            :disabled="!item.new"
            :required="true"
            :title="index + 1 + '-Cevap'"
            type="text"
            min="2"
            max="500"
          />
        </div>
        <div class="w-4/12 px-2">
          <select
            v-model="item.typeId"
            :disabled="!item.new"
            class="w-full px-3 py-2 rounded border"
          >
            <option value="1">Manuel Değer</option>
            <option value="2">Otomatik Değer</option>
          </select>
        </div>
        <div class="w-4/12 px-1">
          <customInput
            v-if="item.typeId == 1"
            v-model="item.score"
            :required="true"
            title="Puan"
            type="number"
            min="0"
            max="255"
          />

          <select
            v-model="item.score"
            :disabled="!item.new"
            class="w-full px-3 py-2 rounded border"
            v-else
          >
          <option value="1">Dakika</option>
            <option value="2">Saat</option>
            <option value="3">Gün</option>
            <option value="4">Hafta (7 Gün)</option>
            <option value="5">Mesaj</option>
          </select>
        </div>
        <div class="w-1/12 mt-1 flex justify-center items-center">
          <button
            class="text-center w-full p-2 rounded border bg-red-500 text-white"
            type="button"
            @click="deletAnswer(item, index)"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>

      <button
        v-if="answers.length < 6"
        class="text-center w-full p-2 rounded border mt-4"
        @click="addAnswer()"
        type="button"
      >
        <i class="fas fa-plus"></i> Yeni Cevap
      </button>

      <asnycButton
        title="Soruyu Kaydet"
        v-if="answers.length > 0 && title && !getAnswerQuestionState"
        mainClass="mt-3"
      />
    </form>
  </div>
</template>

<script>
import customInput from "@/components/general/diffInput";
import asnycButton from "@/components/general/asyncBtn.vue";

import axios from "axios";
import { question, answers } from "@/networking/urlmanager";

export default {
  name: "edit-modal",
  props: ["detail", "surveyId"],
  components: {
    customInput,
    asnycButton,
  },
  data() {
    return {
      showModal: false,
      mouseState: false,
      title: "",

      newAnswers: false,
      answers: [],
    };
  },
  methods: {
    openModal() {
      setTimeout(() => {
        this.title = this.detail.title;
        this.getAnswers();
      }, 50);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.$parent.showSubModal = false;
    },
    addAnswer() {
      if (this.answers.length < 6) {
        this.answers.push({
          id: this.answers.length + 1,
          name: "",
          score: "",
          typeId: "",
          new: true,
        });
      } else {
        alert("Maksimum cevap sayısına ulaştınız daha fazla cevap ekleyemezsiniz !");
      }
    },

    getAnswers() {
      axios
        .get(answers.getAll + "?questionId=" + this.detail.id, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((result) => {
          this.answers = result.data.data.map((r) => {
            return {
              id: r.id,
              name: r.answer,
              score: r.score,
              typeId: r.typeId,
              new: false,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deletAnswer(item, index) {
      if (confirm("Cevabı silmek istediğinize emin misiniz?"))
        axios
          .post(
            answers.delete,
            {
              rowId: item.id,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.answers.splice(index, 1);
          })
          .catch((err) => {
            console.log(err);
            alert(
              "CEVAP SİLİNİRKEN BİR SORUN OLUŞTU LÜTFEN DAHA SONRA TEKRAR DENEYİNİZ!"
            );
          });
    },

    save() {
      this.load = true;
      axios
        .post(
          question.edit,
          {
            rowId: this.detail.id,
            surveyId: this.surveyId,
            title: this.title,
            score: 1,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then(() => {
          this.saveAnsers(this.detail.id);
        })
        .catch((err) => {
          console.log(err);
          alert("Soru eklenirken bir sorun oluştu lütfen daha sonra tekrar deneyiniz!");
          this.load = false;
        });
    },
    saveAnsers(questionId) {
      Promise.all(
        this.answers
          .filter((r) => r.new == true)
          .map((item) => {
            return axios.post(
              answers.add,
              {
                questionId: questionId,
                answer: item.name,
                score: item.score,
                typeId: item.typeId,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            );
          })
      )
        .then(() => {
          alert("Soru başarı ile sisteme eklendi !");
          this.$emit("refresh");
          this.answers = [];
          this.title = "";
          this.load = false;
          this.closeModal();
        })
        .catch((err) => {
          console.log(err);
          alert("Sorunun cevapları sisteme eklenemedi !");
          this.load = false;
        });
    },
  },
  computed: {
    getAnswerQuestionState() {
      return this.answers.filter((r) => !r.name && r.point == "").length > 0
        ? true
        : false;
    },
  },
};
</script>
