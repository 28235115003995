<template>
  <div id="app" class="flex min-h-screen w-full bg-gray-100 relative">
    <navbarItem v-if="$store.state.userData.isAuth" />

    <div class="w-full max-h-screen overflow-y-auto bg-gray-200">
      <headerItem v-if="$store.state.userData.isAuth" />
      <router-view />
    </div>
  </div>
</template>

<script>
// Global Components
import navbarItem from "../src/partition/navbar.vue";
import headerItem from "../src/partition/header.vue";

export default {
  name: "app",
  components: {
    navbarItem,
    headerItem,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
