<template>
  <div class="w-full p-8">
    <div class="w-full flex items-center mt-4 text-3xl font-extralight">
      <i class="fas fa-users mr-2"></i>
      <h5 class="font-extralight">Kullanıcılar</h5>
    </div>

    <detailModal ref="detailModal" />

    <div class="md:flex mt-5">
      <div class="w-full md:w-9/12 flex"></div>
      <div
        class="w-full md:w-3/12 flex justify-center items-center xl:mt-1 mb-1 pr-1 pl-2 rounded md:mt-0 bg-white dark:bg-gray-300 shadow mt-4"
      >
        <i class="fas fa-search w-1/12 mr-2"></i>
        <input
          v-model="search"
          type="search"
          @keypress.enter="getAll()"
          class="w-11/12 border-none focus:ring-0 dark:bg-gray-300 p-2"
          placeholder="Aramak İçin Yazınız..."
        />
      </div>
    </div>

    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
      style="min-height: 300px !important"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th scope="col" class="table-th text-center">#</th>
            <th scope="col" class="table-th text-center">Ad-soyad</th>
            <th scope="col" class="table-th text-center">E-posta</th>
            <th scope="col" class="table-th text-center">Telefon Numarası</th>
            <th scope="col" class="table-th text-center">Seviye</th>
            <th scope="col" class="table-th text-center">Cinsiyet</th>
            <th scope="col" class="table-th text-center">Son İzleme Tarihi</th>
            <!-- <th scope="col" class="table-th text-center">İşlem</th> -->
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td text-center">{{ index + 1 }}</td>
            <td class="table-td text-center">
              {{ item.fullname }}
            </td>
            <td class="table-td text-center">{{ item.email }}</td>
            <td class="table-td text-center">{{ item.phone }}</td>
            <td class="table-td text-center">{{ item.level }}</td>
            <td class="table-td text-center">{{ item.genderName }}</td>
            <td class="table-td text-center">{{ item.lastWatchDate | getDate }}</td>
            <!-- <td class="table-td text-center">
               <button class="process-btn mr-2" @click="$refs.detailModal.openModal()">
                <i class="fas fa-search"></i>
              </button> 

               <button class="process-btn" @click="deleteUser()">
                <i class="fas fa-trash"></i>
              </button> 
            </td> -->
          </tr>

          <tableLoader :load="load" :length="List.length" colspan="7" />
        </tbody>
      </table>
    </div>
    <PaginationComponents
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
import detailModal from "./components/detail.modal";
// Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";

// networking
import axios from "axios";
import { users } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "payment-tracking-table",
  components: {
    tableLoader,
    PaginationComponents,

    detailModal,
  },
  data() {
    return {
      load: false,
      List: [],
      search: "",

      pageCount: 0,
      totalCount: 0,
      currentPage: 0,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(users.getAll + "?search=" + this.search + "&page=" + this.currentPage, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          const { detail, pageCount, totalCount } = res.data.data;
          this.List = detail;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LLL");
      else return "Tarih Yok";
    },
  },
};
</script>

<style lang="scss" scoped></style>
