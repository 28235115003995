<template>
  <div class="w-full bg-gray-50 h-screen flex justify-center items-center">
    <form
      @submit.prevent="signIn()"
      class="w-full xl:w-4/12 bg-white rounded-xl p-6 shadow"
    >
      <h5 class="text-3xl">GİRİŞ YAPIN</h5>
      <p class="mt-2 font-extralight" :class="error && 'text-red-500 lowercase'">
        {{ error ? error : "Tüm alanları eksiksiz ve tam olarak doldurunuza emin olun" }}
      </p>
      <div class="w-full mt-8">
        <customInput
          title="Kullanıcı Adı"
          v-model="username"
          type="text"
          min="3"
          max="30"
          :required="true"
        />
      </div>
      <div class="w-full mt-6">
        <customInput
          title="Parola"
          v-model="password"
          type="password"
          min="6"
          max="20"
          :required="true"
        />
      </div>
      <asyncBtn
        :load="load"
        loadTitle="Bilgileriniz Kontrol Ediliyor"
        mainClass="mt-6"
        title="Giriş Yap"
      />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { auth } from "@/networking/urlmanager";

import customInput from "@/components/general/diffInput";
import asyncBtn from "@/components/general/asyncBtn";
export default {
  name: "authPage",
  components: {
    customInput,
    asyncBtn,
  },
  data() {
    return {
      username: "",
      password: "",
      load: false,

      error: "",
    };
  },
  methods: {
    signIn() {
      this.load = true;
      axios
        .post(auth.signIn, {
          username: this.username,
          password: this.password,
        })
        .then((res) => {
          this.load = false;
          this.$store.commit("signIn", res.data.data);
          this.$router.push("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          this.error = err.response.data.message ? err.response.data.message : err;
          this.load = false;

          setTimeout(() => {
            this.error = "";
          }, 5000);
        });
    },
  },
  created() {
    if (this.$store.state.userData.isAuth) this.$router.push("/dashboard");
  },
};
</script>
