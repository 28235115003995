<template>
  <div class="w-full p-8">
    <div class="w-full flex items-center mt-4 text-3xl font-extralight">
      <i class="fas fa-list-ul mr-2"></i>
      <h5 class="font-extralight">Günün Sözleri</h5>
    </div>

    <addModal ref="addModal" @refresh="getAll" />
    <editModal ref="editModal" :detail="selectedItem" @refresh="getAll" />

    <div class="md:flex mt-5">
      <div class="w-full md:w-9/12 flex">
        <button class="btn w-full md:w-56" @click="() => $refs.addModal.openModal()">
          <i class="fas fa-plus"></i> Yeni Söz
        </button>
      </div>
      <div
        class="w-full md:w-3/12 flex justify-center items-center xl:mt-1 mb-1 pr-1 pl-2 rounded md:mt-0 bg-white dark:bg-gray-300 shadow mt-4"
      >
        <i class="fas fa-search w-1/12 mr-2"></i>
        <input
          v-model="search"
          type="search"
          @keypress.enter="getAll()"
          class="w-11/12 border-none focus:ring-0 dark:bg-gray-300 p-2"
          placeholder="Aramak İçin Yazınız..."
        />
      </div>
    </div>

    <div
      class="shadow table-height-value overflow-x-scroll border-b border-gray-200 bg-gray-200 dark:bg-gray-900 sm:rounded-lg mt-4"
      style="min-height: 300px !important"
    >
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-white border-black border-dotted border-b-2 border-opacity-20">
          <tr>
            <th scope="col" class="table-th text-center">#</th>
            <th scope="col" class="table-th text-left">Söz</th>
            <th scope="col" class="table-th text-left">Modül</th>
            <th scope="col" class="table-th text-center">Tarih</th>
            <th scope="col" class="table-th text-center">İşlem</th>
          </tr>
        </thead>
        <tbody class="divide-y-2 divide-gray-500" style="background-color: #3a3a3a">
          <tr v-for="(item, index) in List" :key="index">
            <td class="table-td text-center">{{ index + 1 }}</td>
            <td class="table-td text-left" style="width:50%;">
                {{ item.words }}
            </td>
            <td class="table-td text-left">
              {{ item.moduleName }}
            </td>
            <td class="table-td text-center">
              {{ item.addDate | getDate }}
            </td>
            <td class="table-td text-center">
              <button
                class="process-btn mr-2"
                @click="() => ((selectedItem = item), $refs.editModal.openModal())"
              >
                <i class="fas fa-search"></i>
              </button>

              <button class="process-btn" @click="deleteItem(item)">
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          <tableLoader :load="load" :length="List.length" colspan="8" />
        </tbody>
      </table>
    </div>
    <PaginationComponents
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
// local components
import addModal from "./components/add.modal";
import editModal from "./components/edit.modal";

// Global Components
import tableLoader from "@/components/general/table-loader.vue";
import PaginationComponents from "@/components/general/pagination.vue";

// networking
import axios from "axios";
import { dailywords } from "@/networking/urlmanager";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "payment-tracking-table",
  components: {
    tableLoader,
    PaginationComponents,

    addModal,
    editModal,
  },
  data() {
    return {
      load: false,

      totalCount: 0,
      pageCount: 1,
      currentPage: 0,

      List: [],
      search: "",

      selectedItem: {
        id: "",
        level: "",
        surveyId: "",
        missionId: "",
        explanation: "",
      },
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      axios
        .get(dailywords.getAll + "?search=" + this.search + "&page=" + this.currentPage, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          this.List = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          alert("İŞLEM BAŞARISIZ OLDU ANKET VERİLERİ GETİRİLEMEDİ !");
        })
        .finally(() => {
          this.load = false;
        });
    },

    deleteItem(item) {
      if (confirm("Sözü silmek istediğinize emin misiniz ?")) {
        axios
          .post(
            dailywords.delete,
            {
              rowId: item.id,
            },
            {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
          )
          .then(() => {
            this.getAll();
          })
          .catch((err) => {
            alert(
              "Söz silinirken bir hata oluştu lütfen daha sonra tekrar deneyiniz !"
            );
            console.log(err);
          });
      }
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return "Tarih Yok";
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>

<style lang="scss" scoped></style>
