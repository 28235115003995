<template>
  <button
    class="w-full rounded shadow p-3 text-white"
    :type="button ? 'button' : 'submit'"
    :class="mainClass + (isSuccess ? ' bg-green-600' : ' bg-red-600')"
    :disabled="load"
    @click="button && $emit('click')"
  >
    <i v-if="load" class="fas fa-spinner fa-spin"></i>
    {{ load ? (loadTitle ? loadTitle : "Kayıt Ediliyor") : title }}
  </button>
</template>

<script>
export default {
  name: "asyncBtn",
  props: ["load", "title", "mainClass", "button", "loadTitle", "isSuccess"],
};
</script>

<style></style>
