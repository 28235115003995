<template>
  <div
    v-if="pageCount > 0"
    class="pt-5 xl:flex items-center justify-between border-white-custom md:px-6 xl:px-3 py-3 w-full text-black text-sm xl:text-base mt-4"
  >
    {{ totalRowCount }} Adet kayıt listelendi
    <paginate
      :page-count="pageCount"
      :page-range="isMobile ? 2 : 6"
      :click-handler="(r) => $emit('change', r - 1)"
      active-class="w-full bg-red-500 rounded text-white"
      :prev-text="'Önceki'"
      :next-text="'Sonraki'"
      container-class="flex text-sm xl:text-base mt-4 xl:mt-0"
      page-class="mx-2  px-3"
    >
    </paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
export default {
  props: ["pageCount", "currentPage", "totalRowCount"],
  name: "pagination-components",
  model: {
    prop: "currentPage",
    event: "change",
  },
  components: {
    Paginate,
  },
  data() {
    return {
      current: 0,
      isMobile: false,
    };
  },
  methods: {
    changepage(val) {
      console.log(val);
    },
  },
  watch: {
    current(val) {
      if (val != this.currentPage) {
        this.$emit("change", val);
      }
    },
    currentPage(val) {
      this.current = val;
    },
  },
  created() {
    this.isMobile = window.innerWidth < 1024;
  },
};
</script>

<style></style>
